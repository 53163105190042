.container,
.container-fluid,
.container-huge,
.container-xxxl,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	width: 100%;
	padding-right: calc(var(--bs-gutter-x) * 0.5);
	padding-left: calc(var(--bs-gutter-x) * 0.5);
	margin-right: auto;
	margin-left: auto;
}

@media (min-width: 576px) {
	.container-sm,
	.container {
		max-width: 100%;
		padding: 0 2rem;
	}
}
@media (min-width: 768px) {
	.container-md,
	.container-sm,
	.container {
		max-width: 100%;
		padding: 0 2rem;
	}
}
@media (min-width: 992px) {
	.container-lg,
	.container-md,
	.container-sm,
	.container {
		max-width: 100%;
		padding: 0 2rem;
	}
}
@media (min-width: 1200px) {
	.container-xl,
	.container-lg,
	.container-md,
	.container-sm,
	.container {
		max-width: 100%;
		padding: 0 2rem;
	}
}
@media (min-width: 1400px) {
	.container-xxl,
	.container-xl,
	.container-lg,
	.container-md,
	.container-sm,
	.container {
		max-width: 100%;
	}
}

@media (min-width: 1600px) {
	.container-xxxl,
	.container-xxl,
	.container-xl,
	.container-lg,
	.container-md,
	.container-sm,
	.container {
		max-width: 100%;
	}
}

@media (min-width: 1800px) {
	.container-huge,
	.container-xxxl,
	.container-xxl,
	.container-xl,
	.container-lg,
	.container-md,
	.container-sm,
	.container {
		max-width: 100%;
	}
}

@media (min-width: 1920px) {
	.container-xxxl,
	.container-xxl,
	.container-xl,
	.container-lg,
	.container-md,
	.container-sm,
	.container {
		max-width: 1920px;
	}
}

:root {
	--bs-breakpoint-xs: 0;
	--bs-breakpoint-sm: 576px;
	--bs-breakpoint-md: 768px;
	--bs-breakpoint-lg: 992px;
	--bs-breakpoint-xl: 1200px;
	--bs-breakpoint-xxl: 1400px;
	--bs-breakpoint-xxxl: 1600px;
	--bs-breakpoint-huge: 1800px;
}

.row {
	margin-right: 0 !important;
	margin-left: 0 !important;
}

/* Backdrop menu */

.offcanvas-backdrop,
.offcanvas.offcanvas-top {
	top: 50px;
}

.offcanvas.offcanvas-top {
	height: 100dvh;
}

.popover {
	z-index: 99 !important;
}
